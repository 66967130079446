<template>
  <div class="wizard-step-container">
    <h4 class="mb-3 font-weight-bold text-dark">{{ $t("Physical Person Setup") }}</h4>
    <p class="mb-3 mt-8 font-weight-bold">{{ $t("Add login email") }}:</p>
    <validation-provider ref="userValidator" v-slot="{ errors }" :name="$t('Email')" vid="mail" :rules="{
      required: true,
      email_unique: physicalPersonInfo.login_email,
    }">
      <euro-input v-model="emailComputed" :error-messages="errors"
        :label="`${$t('Email (this email will be used for login)')}*`"
        :placeholder="$t('Please enter your email')"></euro-input>
    </validation-provider>

    <div class="form row">
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Roles')" vid="roles">
          <p class="mb-3 mt-5 font-weight-bold">{{ $t("Add physical person role") }}:</p>
          <euro-select v-model="roleComputed" :label="`${$t('Roles')}*`" :placeholder="$t('Select role')" :options="roles"
            searchable :error-messages="errors"></euro-select>
        </validation-provider>
      </div>
      <div v-if="roleComputed == '0' && hasAnyPermission" class="col-12">
        <b-card no-body class="mb-3">
          <b-card-header header-tag="header" role="tab">
            <b-button v-b-toggle.accordion-1 block variant="info">
              <i class="fas fa-user-shield"></i>
              {{ $t("Set user permission") }}
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="d-flex flex-column mb-6">
                <label v-if="isApStaff || getCurrentManagedCompanyPaycheckPermission" v-b-tooltip.hover class="checkbox checkbox-lg mb-3"
                  :title="$t(`By enabling this permission, the user will be able to view and download every employees paycheck`)">
                  <input v-model="paycheckPermissionComputed" type="checkbox" />
                  <span class="mr-3"></span>
                  {{ $t("Paycheck permission") }}
                </label>
                <label v-if="isApStaff || getCurrentManagedCompanyCertificateSuitabilityPermission" v-b-tooltip.hover class="checkbox checkbox-lg mb-3"
                  :title="$t(`By enabling this permission, the user will be able to view and download every employees medical certificate`)">
                  <input v-model="certificateSuitabilityComputed" type="checkbox" />
                  <span class="mr-3"></span>
                  {{ $t("Certificate suitability for work permission") }}
                </label>
                <label v-if="isApStaff || getCurrentManagedCompanyIdentityDocumentPermission" v-b-tooltip.hover class="checkbox checkbox-lg mb-3"
                  :title="$t(`By enabling this permission, the user will be able to view and download every employees identity document`)">
                  <input v-model="identityDocumentPermissionComputed" type="checkbox" />
                  <span class="mr-3"></span>
                  {{ $t("Identity document permission") }}
                </label>
                <label v-if="isApStaff || getCurrentManagedCompanyContractPermission" v-b-tooltip.hover class="checkbox checkbox-lg mb-3"
                  :title="$t(`By enabling this permission, the user will be able to view and download every employees contract`)">
                  <input v-model="contractPermissionComputed" type="checkbox" />
                  <span class="mr-3"></span>
                  {{ $t("Contract permission") }}
                </label>
                <label v-if="isApStaff || getCurrentManagedCompanyPostingCertificatePermission" v-b-tooltip.hover class="checkbox checkbox-lg mb-3"
                  :title="$t(`By enabling this permission, the user will be able to view and download every employees A1 certificate`)">
                  <input v-model="postingCertificatePermissionComputed" type="checkbox" />
                  <span class="mr-3"></span>
                  {{ $t("A1 Certificate permission") }}
                </label>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <div class="col-12">
        <p class="mb-6 mt-8 font-weight-bold">{{ $t("Physical person details") }}:</p>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Avatar')" vid="avatar_tus">
          <avatar-input :error-messages="errors"
            @change="tusId => { physicalPersonInfo.avatar_tus = tusId; }"></avatar-input>
        </validation-provider>
      </div>
      <div class="col-12">
        <div class="d-flex align-items-center mb-5">
          <label class="checkbox checkbox-lg">
            <input v-model="physicalPersonInfo.is_employee" type="checkbox" />
            <span class="mr-3"></span>
            {{ $t("Add as employee") }}
          </label>
        </div>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :label="`${$t('Title')}`" rules="max:100">
          <euro-input :value="physicalPersonInfo.title" :label="`${$t('Title')}`"
            :placeholder="$t('Legal representative, owner...')" :error-messages="errors"
            @input="$emit('update:title', $event)"></euro-input>
        </validation-provider>
      </div>
      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Name')" rules="required|max:30">
          <euro-input :value="physicalPersonInfo.name" :label="`${$t('First name')}*`" :error-messages="errors"
            @input="$emit('update:name', $event)"></euro-input>
        </validation-provider>
      </div>
      <div class="col-6">
        <validation-provider v-slot="{ errors }" :name="$t('Last name')" vid="surname" rules="required|max:30">
          <euro-input :value="physicalPersonInfo.surname" :label="`${$t('Last name')}*`" :error-messages="errors"
            @input="$emit('update:surname', $event)"></euro-input>
        </validation-provider>
      </div>
      <div class="col-6">
        <validation-provider v-slot="{ errors }" :gender="$t('Gender')" vid="gender" rules="required">
          <euro-radio v-model="physicalPersonInfo.gender" :options="GENDERS" :label="`${$t('Gender')}*`"
            :error-messages="errors"></euro-radio>
        </validation-provider>
      </div>
      <div class="col-6">
        <validation-provider ref="dateBirthProvider" v-slot="{ errors }" :name="$t('Date of birth')" vid="date_birth">
          <date-picker v-model="physicalPersonInfo.date_birth"
            :overrides="{ maxDate: moment(maxDate), useCurrent: false }" :error-messages="errors" placeholder="YYYY-MM-DD"
            :label="`${$t('Date of birth')}`">
          </date-picker>
        </validation-provider>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Place of birth')" vid="place_birth">
          <euro-place-input v-model="physicalPersonInfo.place_birth" :label="`${$t('Place of birth')}`"
            :error-messages="errors"></euro-place-input>
        </validation-provider>
      </div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Citizenships')" vid="citizenships" rules="required">
          <euro-select v-model="citizenshipsComp" :label="`${$t('Citizenships')}*`"
            :placeholder="$t('Select citizenships')" :options="nationsSelectable" multiple searchable
            :error-messages="errors"></euro-select>
        </validation-provider>
      </div>
    </div>

  </div>
</template>

<script>
import moment from "moment";
import PhysicalPersonDetailService from "@/core/services/physical-person/physical-person-detail.service";
import PhysicalPersonTelephoneService from "@/core/services/physical-person/physical-person-telephone.service";
import datePicker from "@/view/components/datePicker/datePicker.vue";
import { mapGetters } from "vuex";
import DateService from "@/core/services/date.service";
import { mapState } from "vuex";

export default {
  components: {
    datePicker,
  },
  props: {
    inviteType: {
      type: String,
      required: true
    },
    physicalPersonInfo: {
      type: Object,
      default: null
    },
    companyInfo: {
      type: Object,
      default: null
    },
    role: {
      type: String,
      default: null
    },
    certificateSuitability: {
      type: Boolean,
      required: true
    },
    paycheckPermission: {
      type: Boolean,
      required: true
    },
    identityDocumentPermission: {
      type: Boolean,
      required: true
    },
    contractPermission: {
      type: Boolean,
      required: true
    },
    postingCertificatePermission: {
      type: Boolean,
      required: true
    },
  },

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      DateService,
      maxDate: today,
      TELEPHONE_KINDS: [],
      GENDERS: [],
      multiTelephonesInputKey: 0,
      userEmail: "",
      nations: []
    };
  },

  computed: {
    ...mapGetters("user", ["isApStaff", "date_format", "dateFormat", "isAfterSale", "getCurrentManagedCompanyPaycheckPermission", "getCurrentManagedCompanyCertificateSuitabilityPermission", "getCurrentManagedCompanyContractPermission", "getCurrentManagedCompanyIdentityDocumentPermission", "getCurrentManagedCompanyPostingCertificatePermission"]),
    ...mapState("constants", ["countries"]),

    nationsSelectable() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    citizenshipsComp: {
      get() {
        return this.physicalPersonInfo.citizenship_set.map(el => this.$t(el.country));
      },
      set(val) {
        this.physicalPersonInfo.citizenship_set = val.map(el => ({ country: el }));
      },
    },

    emailComputed: {
      get() {
        return this.physicalPersonInfo.login_email;
      },
      set(val) {
        this.$emit("updateUserEmail", val);
      },
    },
    roleComputed: {
      get() {
        return this.role;
      },
      set(val) {
        this.setEmployee(val);
        this.$emit("updateUserRole", val);
        if (val == '1') {
          this.$emit("updateCertificateSuitability", false);
          this.$emit("updatePaycheckPermission", false);
          this.$emit("updateContractPermission", false);
          this.$emit("updatePostingCertificatePermission", false);
          this.$emit("updateIdentityDocumentPermission", false);
        }
      },
    },
    hasAnyPermission() {
      return this.isApStaff || this.getCurrentManagedCompanyPaycheckPermission || this.getCurrentManagedCompanyCertificateSuitabilityPermission || this.getCurrentManagedCompanyContractPermission || this.getCurrentManagedCompanyIdentityDocumentPermission || this.getCurrentManagedCompanyPostingCertificatePermission;
    },
    paycheckPermissionComputed: {
      get() {
        return this.paycheckPermission;
      },
      set(val) {
        this.$emit("updatePaycheckPermission", val);
      },
    },
    certificateSuitabilityComputed: {
      get() {
        return this.certificateSuitability;
      },
      set(val) {
        this.$emit("updateCertificateSuitability", val);
      },
    },
    identityDocumentPermissionComputed: {
      get() {
        return this.identityDocumentPermission;
      },
      set(val) {
        this.$emit("updateIdentityDocumentPermission", val);
      },
    },
    contractPermissionComputed: {
      get() {
        return this.contractPermission;
      },
      set(val) {
        this.$emit("updateContractPermission", val);
      },
    },
    postingCertificatePermissionComputed: {
      get() {
        return this.postingCertificatePermission;
      },
      set(val) {
        this.$emit("updatePostingCertificatePermission", val);
      },
    },
    stringMaxDate() {
      return moment(this.maxDate, true).format(this.date_format);
    },
    computedDisabledPhone() {
      return this.physicalPersonInfo.phone[0]?.number ?? "";
    },

    countriesSelectProps() {
      return {
        options: this.countries,
        "track-by": "iso",
        label: "name",
        multiple: true,
        "allow-empty": true,
        searchable: true,
      };
    },

    groupsSelectProps() {
      return {
        options: this.groupsChoices,
        "track-by": "id",
        label: "name",
        multiple: true,
        "allow-empty": true,
        searchable: true,
      };
    },
    countriesSelect() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    roles() {
      if (this.isAfterSale) {
        return [
          { value: "1", text: 'After sale' }
        ]
      }

      return [
        { value: "0", text: 'Administrator' },
        { value: "1", text: 'After sale' }
      ]
    }
  },
  
  watch: {
    roleComputed(newRole) {
      if (newRole === '0') {
        this.$emit("updateCertificateSuitability", true);
        this.$emit("updatePaycheckPermission", true);
        this.$emit("updateContractPermission", true);
        this.$emit("updatePostingCertificatePermission", true);
        this.$emit("updateIdentityDocumentPermission", true);
      }
    }
  },

  async mounted() {
    this.nations = this.countries;
    PhysicalPersonDetailService.getGenders()
      .then((res) => res.map(el => ({ value: el.value, text: el.display_name })))
      .then(genKinds => (this.GENDERS = genKinds))
      .catch((err) => {
        console.error(err);
        this.$bvToast.toast(this.$t("Failed to load genders. Please try again."), {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
          noAutoHide: true,
        });
      })
    PhysicalPersonTelephoneService.getKinds()
      .then(res => res.map(el => ({ value: el.value, text: el.display_name })))
      .then(telKinds => (this.TELEPHONE_KINDS = telKinds))
      .catch(err => {
        console.error(err);
        this.$bvToast.toast(this.$t("Failed to load telephone kinds. Please try again."), {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
          noAutoHide: true,
        });
      })
  },

  methods: {
    moment,
    handleDisabled(e) {
      if (this.userSetup.selected) e.stopPropagation();
    },
    updateMailValue(event) {
      this.$emit("update:mail", event);
    },
    setEmployee(role) {
      if (role == "employee") {
        this.physicalPersonInfo.is_employee = true;
      } else {
        this.physicalPersonInfo.is_employee = false;
      }
    }
  },
};
</script>

<style scoped>
.wizard-step-container {
  max-width: 600px;
  margin: 0 auto;
}
</style>
