<template>
  <b-card>
    <b-card-title> {{ $t("Invite a new Atlasposting user") }} </b-card-title>
    <UserCreateWizard :is-submitting="isSubmitting" :invite-type="getInviteType" @submit="submitUser"></UserCreateWizard>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import UserCreateWizard from "@/view/components/wizards/UserCreateWizard.vue";
import { mapGetters, mapState } from "vuex";
import InvitationService from "@/core/services/invitation/invitation.service";
import { successSwal, backendErrorSwal } from "@/core/helpers/swal";
export default {
  components: {
    UserCreateWizard,
  },

  data: () => ({
    isSubmitting: false,
  }),

  computed: {
    ...mapGetters("user", ["getCurrentManagedCompanyId"]),
    ...mapState("constants", ["countries"]),

    getInviteType() {
      return "user";
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Atlasposting users"), route: { name: "focus-users.user-manage" } },
      { title: this.$t("Create") },
    ]);
  },

  methods: {

    async submitUser(body) {
      this.isSubmitting = true;
      try {
        await InvitationService.createInvitation(body);
        await successSwal({
          text: "Invitation sent",
          title: "Success"
        }).then(() => this.$router.push({name: "focus-users.user-manage"}));
      } catch (err) {
        console.error(err);
        let message = '';
        if (err.response?.data) {
          for (let key in err.response.data) {
            if (err.response.data.hasOwnProperty(key)) {
              message += err.response.data[key][0] + ' ';
            }
          }
        } else {
          message = "Unable to send the invitation";
        }
        await backendErrorSwal(err, message);
      } finally {
        this.isSubmitting = false;
      }
    }
  },
};
</script>
