var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-card-title', [_vm._v(" " + _vm._s(_vm.$t("Invite a new Atlasposting user")) + " ")]), _c('UserCreateWizard', {
    attrs: {
      "is-submitting": _vm.isSubmitting,
      "invite-type": _vm.getInviteType
    },
    on: {
      "submit": _vm.submitUser
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }