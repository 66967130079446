var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wizard-step-container"
  }, [_c('h4', {
    staticClass: "mb-3 font-weight-bold text-dark"
  }, [_vm._v(_vm._s(_vm.$t("Physical Person Setup")))]), _c('p', {
    staticClass: "mb-3 mt-8 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t("Add login email")) + ":")]), _c('validation-provider', {
    ref: "userValidator",
    attrs: {
      "name": _vm.$t('Email'),
      "vid": "mail",
      "rules": {
        required: true,
        email_unique: _vm.physicalPersonInfo.login_email
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-input', {
          attrs: {
            "error-messages": errors,
            "label": "".concat(_vm.$t('Email (this email will be used for login)'), "*"),
            "placeholder": _vm.$t('Please enter your email')
          },
          model: {
            value: _vm.emailComputed,
            callback: function callback($$v) {
              _vm.emailComputed = $$v;
            },
            expression: "emailComputed"
          }
        })];
      }
    }])
  }), _c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Roles'),
      "vid": "roles"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('p', {
          staticClass: "mb-3 mt-5 font-weight-bold"
        }, [_vm._v(_vm._s(_vm.$t("Add physical person role")) + ":")]), _c('euro-select', {
          attrs: {
            "label": "".concat(_vm.$t('Roles'), "*"),
            "placeholder": _vm.$t('Select role'),
            "options": _vm.roles,
            "searchable": "",
            "error-messages": errors
          },
          model: {
            value: _vm.roleComputed,
            callback: function callback($$v) {
              _vm.roleComputed = $$v;
            },
            expression: "roleComputed"
          }
        })];
      }
    }])
  })], 1), _vm.roleComputed == '0' && _vm.hasAnyPermission ? _c('div', {
    staticClass: "col-12"
  }, [_c('b-card', {
    staticClass: "mb-3",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    attrs: {
      "header-tag": "header",
      "role": "tab"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.accordion-1",
      modifiers: {
        "accordion-1": true
      }
    }],
    attrs: {
      "block": "",
      "variant": "info"
    }
  }, [_c('i', {
    staticClass: "fas fa-user-shield"
  }), _vm._v(" " + _vm._s(_vm.$t("Set user permission")) + " ")])], 1), _c('b-collapse', {
    attrs: {
      "id": "accordion-1",
      "accordion": "my-accordion",
      "role": "tabpanel"
    }
  }, [_c('b-card-body', [_c('div', {
    staticClass: "d-flex flex-column mb-6"
  }, [_vm.isApStaff || _vm.getCurrentManagedCompanyPaycheckPermission ? _c('label', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "checkbox checkbox-lg mb-3",
    attrs: {
      "title": _vm.$t("By enabling this permission, the user will be able to view and download every employees paycheck")
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paycheckPermissionComputed,
      expression: "paycheckPermissionComputed"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.paycheckPermissionComputed) ? _vm._i(_vm.paycheckPermissionComputed, null) > -1 : _vm.paycheckPermissionComputed
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.paycheckPermissionComputed,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.paycheckPermissionComputed = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.paycheckPermissionComputed = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.paycheckPermissionComputed = $$c;
        }
      }
    }
  }), _c('span', {
    staticClass: "mr-3"
  }), _vm._v(" " + _vm._s(_vm.$t("Paycheck permission")) + " ")]) : _vm._e(), _vm.isApStaff || _vm.getCurrentManagedCompanyCertificateSuitabilityPermission ? _c('label', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "checkbox checkbox-lg mb-3",
    attrs: {
      "title": _vm.$t("By enabling this permission, the user will be able to view and download every employees medical certificate")
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.certificateSuitabilityComputed,
      expression: "certificateSuitabilityComputed"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.certificateSuitabilityComputed) ? _vm._i(_vm.certificateSuitabilityComputed, null) > -1 : _vm.certificateSuitabilityComputed
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.certificateSuitabilityComputed,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.certificateSuitabilityComputed = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.certificateSuitabilityComputed = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.certificateSuitabilityComputed = $$c;
        }
      }
    }
  }), _c('span', {
    staticClass: "mr-3"
  }), _vm._v(" " + _vm._s(_vm.$t("Certificate suitability for work permission")) + " ")]) : _vm._e(), _vm.isApStaff || _vm.getCurrentManagedCompanyIdentityDocumentPermission ? _c('label', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "checkbox checkbox-lg mb-3",
    attrs: {
      "title": _vm.$t("By enabling this permission, the user will be able to view and download every employees identity document")
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.identityDocumentPermissionComputed,
      expression: "identityDocumentPermissionComputed"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.identityDocumentPermissionComputed) ? _vm._i(_vm.identityDocumentPermissionComputed, null) > -1 : _vm.identityDocumentPermissionComputed
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.identityDocumentPermissionComputed,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.identityDocumentPermissionComputed = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.identityDocumentPermissionComputed = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.identityDocumentPermissionComputed = $$c;
        }
      }
    }
  }), _c('span', {
    staticClass: "mr-3"
  }), _vm._v(" " + _vm._s(_vm.$t("Identity document permission")) + " ")]) : _vm._e(), _vm.isApStaff || _vm.getCurrentManagedCompanyContractPermission ? _c('label', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "checkbox checkbox-lg mb-3",
    attrs: {
      "title": _vm.$t("By enabling this permission, the user will be able to view and download every employees contract")
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.contractPermissionComputed,
      expression: "contractPermissionComputed"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.contractPermissionComputed) ? _vm._i(_vm.contractPermissionComputed, null) > -1 : _vm.contractPermissionComputed
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.contractPermissionComputed,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.contractPermissionComputed = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.contractPermissionComputed = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.contractPermissionComputed = $$c;
        }
      }
    }
  }), _c('span', {
    staticClass: "mr-3"
  }), _vm._v(" " + _vm._s(_vm.$t("Contract permission")) + " ")]) : _vm._e(), _vm.isApStaff || _vm.getCurrentManagedCompanyPostingCertificatePermission ? _c('label', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "checkbox checkbox-lg mb-3",
    attrs: {
      "title": _vm.$t("By enabling this permission, the user will be able to view and download every employees A1 certificate")
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.postingCertificatePermissionComputed,
      expression: "postingCertificatePermissionComputed"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.postingCertificatePermissionComputed) ? _vm._i(_vm.postingCertificatePermissionComputed, null) > -1 : _vm.postingCertificatePermissionComputed
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.postingCertificatePermissionComputed,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.postingCertificatePermissionComputed = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.postingCertificatePermissionComputed = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.postingCertificatePermissionComputed = $$c;
        }
      }
    }
  }), _c('span', {
    staticClass: "mr-3"
  }), _vm._v(" " + _vm._s(_vm.$t("A1 Certificate permission")) + " ")]) : _vm._e()])])], 1)], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "col-12"
  }, [_c('p', {
    staticClass: "mb-6 mt-8 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t("Physical person details")) + ":")])]), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Avatar'),
      "vid": "avatar_tus"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('avatar-input', {
          attrs: {
            "error-messages": errors
          },
          on: {
            "change": function change(tusId) {
              _vm.physicalPersonInfo.avatar_tus = tusId;
            }
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-5"
  }, [_c('label', {
    staticClass: "checkbox checkbox-lg"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.physicalPersonInfo.is_employee,
      expression: "physicalPersonInfo.is_employee"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.physicalPersonInfo.is_employee) ? _vm._i(_vm.physicalPersonInfo.is_employee, null) > -1 : _vm.physicalPersonInfo.is_employee
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.physicalPersonInfo.is_employee,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.physicalPersonInfo, "is_employee", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.physicalPersonInfo, "is_employee", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.physicalPersonInfo, "is_employee", $$c);
        }
      }
    }
  }), _c('span', {
    staticClass: "mr-3"
  }), _vm._v(" " + _vm._s(_vm.$t("Add as employee")) + " ")])])]), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "label": "".concat(_vm.$t('Title')),
      "rules": "max:100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('euro-input', {
          attrs: {
            "value": _vm.physicalPersonInfo.title,
            "label": "".concat(_vm.$t('Title')),
            "placeholder": _vm.$t('Legal representative, owner...'),
            "error-messages": errors
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('update:title', $event);
            }
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Name'),
      "rules": "required|max:30"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('euro-input', {
          attrs: {
            "value": _vm.physicalPersonInfo.name,
            "label": "".concat(_vm.$t('First name'), "*"),
            "error-messages": errors
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('update:name', $event);
            }
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Last name'),
      "vid": "surname",
      "rules": "required|max:30"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('euro-input', {
          attrs: {
            "value": _vm.physicalPersonInfo.surname,
            "label": "".concat(_vm.$t('Last name'), "*"),
            "error-messages": errors
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('update:surname', $event);
            }
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "gender": _vm.$t('Gender'),
      "vid": "gender",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('euro-radio', {
          attrs: {
            "options": _vm.GENDERS,
            "label": "".concat(_vm.$t('Gender'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.physicalPersonInfo.gender,
            callback: function callback($$v) {
              _vm.$set(_vm.physicalPersonInfo, "gender", $$v);
            },
            expression: "physicalPersonInfo.gender"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    ref: "dateBirthProvider",
    attrs: {
      "name": _vm.$t('Date of birth'),
      "vid": "date_birth"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('date-picker', {
          attrs: {
            "overrides": {
              maxDate: _vm.moment(_vm.maxDate),
              useCurrent: false
            },
            "error-messages": errors,
            "placeholder": "YYYY-MM-DD",
            "label": "".concat(_vm.$t('Date of birth'))
          },
          model: {
            value: _vm.physicalPersonInfo.date_birth,
            callback: function callback($$v) {
              _vm.$set(_vm.physicalPersonInfo, "date_birth", $$v);
            },
            expression: "physicalPersonInfo.date_birth"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Place of birth'),
      "vid": "place_birth"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('euro-place-input', {
          attrs: {
            "label": "".concat(_vm.$t('Place of birth')),
            "error-messages": errors
          },
          model: {
            value: _vm.physicalPersonInfo.place_birth,
            callback: function callback($$v) {
              _vm.$set(_vm.physicalPersonInfo, "place_birth", $$v);
            },
            expression: "physicalPersonInfo.place_birth"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Citizenships'),
      "vid": "citizenships",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('euro-select', {
          attrs: {
            "label": "".concat(_vm.$t('Citizenships'), "*"),
            "placeholder": _vm.$t('Select citizenships'),
            "options": _vm.nationsSelectable,
            "multiple": "",
            "searchable": "",
            "error-messages": errors
          },
          model: {
            value: _vm.citizenshipsComp,
            callback: function callback($$v) {
              _vm.citizenshipsComp = $$v;
            },
            expression: "citizenshipsComp"
          }
        })];
      }
    }])
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }