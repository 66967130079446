<template>
  <wizard-left ref="userCreateWizardForm" :clickable-steps="false">
    <template #wizard-nav-left>
      <wizard-nav-step-left :title="$t('Physical person setup')" :desc="$t('Add email and details')"
        icon="General/User.svg"></wizard-nav-step-left>
      <wizard-nav-step-left :title="$t('Contacts and address')" :desc="$t('Add telephone number and address')"
        icon="Map/Marker1.svg"></wizard-nav-step-left>
      <wizard-nav-step-left :title="$t('Completed!')" :desc="$t('Review and Submit')" icon="General/Like.svg" />
    </template>

    <template #default="{ currentStep, goPrev }">
      <validation-observer ref="mainValidationObserver" v-slot="{ handleSubmit }">
        <wizard-step-left class="custom-wizard-step">
          <keep-alive>
            <validation-observer v-if="currentStep === 1" ref="validator-1">
              <div v-if="inviteType == 'user'">
                <PhysicalPersonInfoForm v-bind.sync="physicalPersonInfo" :invite-type="inviteType"
                  :physical-person-info="physicalPersonInfo" :role="role"
                  :certificate-suitability="certificateSuitability" :paycheck-permission="paycheckPermission"
                  :contract-permission="contractPermission" :identity-document-permission="identityDocumentPermission"
                  :posting-certificate-permission="postingCertificatePermission"
                  @updateUserEmail="handleEmailUpdate" @updateUserRole="handleRoleUpdate"
                  @updateCertificateSuitability="updateCertificateSuitability"
                  @updatePaycheckPermission="updatePaycheckPermission"
                  @updateContractPermission="updateContractPermission" 
                  @updateIdentityDocumentPermission="updateIdentityDocumentPermission"
                  @updatePostingCertificatePermission="updatePostingCertificatePermission">
                </PhysicalPersonInfoForm>
              </div>
            </validation-observer>
          </keep-alive>
        </wizard-step-left>

        <wizard-step-left class="custom-wizard-step">
          <validation-observer v-if="currentStep === 2" ref="validator-2">
            <div class="row">
              <div class="col-12">
                <h4 class="mb-3 font-weight-bold text-dark">{{ $t("Contacts and address") }}</h4>
              </div>
              <div class="col-12">
                <div class="pb-4">
                  <multi-telephones-input :key="multiTelephonesInputKey" v-model="physicalPersonInfo.telcontact_set"
                    :label="`${$t('Telephone number')}`" :is-multi="false"
                    :options="TELEPHONE_KINDS"></multi-telephones-input>
                </div>
              </div>
              <div class="col-12">
                <p class="mb-6 mt-5 font-weight-bold">{{ $t("Address") }}:</p>
              </div>
              <div class="col-9">
                <validation-provider v-slot="{ errors }" vid="address_kind">
                  <euro-select v-model="addresses.kind" :label="$t('Kind')" :options="selectableKinds"
                    :error-messagess="errors"></euro-select>
                </validation-provider>
              </div>
              <div class="col-3">
                <div class="form-group mb-0 mt-12">
                  <div class="d-flex align-items-center justify-content-end">
                    <label class="col-form-label">{{ $t('Main address') }}</label>
                    <div class="ml-3">
                      <span class="switch switch-sm switch-icon">
                        <label>
                          <input v-model="addresses.main" type="checkbox" />
                          <span></span>
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" vid="address_address" :rules="{ required: true }">
                  <AddressInput v-model="addresses.address" :label="$t('Address')" :active-step="currentStep === 3"
                    :error-messages="errors" :address-center="addressCenter" :manual-address="addresses.manual_address"
                    @change-manual-address="changeManualAddress" @update-composed-address="updateComposedAddress">
                  </AddressInput>
                </validation-provider>
              </div>
            </div>
          </validation-observer>
        </wizard-step-left>

        <wizard-step-left v-if="currentStep === 3" class="custom-wizard-step">
          <h3 class="font-weight-bolder mb-3">{{ $t("Confirm information to send invite") }}</h3>
          <div class="separator separator-dashed my-5"></div>
          <h6 class="font-weight-bolder mb-3">{{ $t("Physical person setup") }}</h6>
          <div class="text-dark-50 line-height-lg">
            <div>
              {{ $t("Email") }}: <span>{{ physicalPersonInfo.login_email }}</span>
            </div>
            <div v-if="physicalPersonInfo.title">
              {{ $t("Title") }}: <span>{{ physicalPersonInfo.title }}</span>
            </div>
            <div>
              {{ $t("Name") }}: <span>{{ physicalPersonInfo.name }}</span>
            </div>
            <div>
              {{ $t("Last name") }}:
              <span>{{ physicalPersonInfo.surname }}</span>
            </div>
            <div v-if="physicalPersonInfo.gender">
              {{ $t("Gender") }}:
              <span>{{ physicalPersonInfo.gender == 'MA' ? $t("Male") : $t("Female") }}</span>
            </div>
            <div v-if="physicalPersonInfo.place_birth">
              {{ $t("Place of birth") }}:
              <span>{{ physicalPersonInfo.place_birth.address_components[0].long_name }}</span>
            </div>
            <div v-if="physicalPersonInfo.date_birth">
              {{ $t("Date of birth") }}:
              <span>{{ physicalPersonInfo.date_birth }}</span>
            </div>
            <div v-if="role">
              {{ $t("Role") }}:
              <span>{{ getRole }}</span>
            </div>
          </div>
          <div v-if="addresses?.address?.formatted_address || telephoneNumbers.length > 0">
            <div class="separator separator-dashed my-5"></div>
            <h6 class="font-weight-bolder mb-3">{{ $t("Contact and address") }}</h6>
            <div v-if="telephoneNumbers.length > 0">
              <div v-for="phone in telephoneNumbers" :key="phone.number" class="text-dark-50 line-height-lg">
                {{ TELEPHONE_KINDS.find(el => el.value == phone.kind).text }}: {{ phone.number }}
              </div>
            </div>
            <div v-if="addresses?.address?.formatted_address">
              <div class="text-dark-50 line-height-lg">
                {{ $t("Address") }}:
                <span v-if="addresses.manual_address">{{ addresses.composed_address.formatted }}</span>
                <span v-else>{{ addresses.address?.formatted_address ?? "No address found" }}</span>
                <div v-if="addresses.description">
                  {{ $t("Description") }}: <span>{{ addresses.description }}</span>
                </div>
              </div>
            </div>
          </div>
        </wizard-step-left>

        <wizard-actions-left>
          <div class="mr-2">
            <wizard-prev-btn-left @click="goPrev"></wizard-prev-btn-left>
          </div>
          <div>
            <wizard-submit-btn-left :label="$t('Save as Confirmed')" @click="handleSubmit(submit)">
            </wizard-submit-btn-left>
            <wizard-next-btn-left @click="handleNext"></wizard-next-btn-left>
          </div>
        </wizard-actions-left>
      </validation-observer>
    </template>
  </wizard-left>
</template>

<script>
import PhysicalPersonAddressService from "@/core/services/physical-person/physical-person-address.service";
import PhysicalPersonInfoForm from "@/view/components/forms/physical-person/PhysicalPersonInfoForm.vue";
import CompanyAddressService from "@/core/services/company/company-address.service";
import PhysicalPersonTelephoneService from "@/core/services/physical-person/physical-person-telephone.service";
import icons from "@/core/config/icons";
import Swal from "sweetalert2";
import { mapState } from "vuex";
import AddressService from "@/core/services/address.service.js"

export default {
  components: {
    PhysicalPersonInfoForm,
  },

  props: {
    errorMessages: {
      type: Object,
      default: null,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    adminUserType: {
      type: String,
      default: null,
    },
    inviteType: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    icons,
    role: null,
    physicalPersonInfo: {
      id: null,
      login_email: "",
      name: "",
      surname: "",
      avatar_tus: null,
      date_birth: null,
      place_birth: null,
      gender: "MA",
      citizenship_set: [],
      telcontact_set: [],
      socialcontacts_set: [],
      is_employee: false,
      title: ""
    },
    companyInfo: {
      id: null,
      companyName: null,
      country: null,
      vat: null
    },
    addresses: {
      address: null,
      address_id: null,
      description: null,
      kind: "0",
      main: true,
      manual_address: false,
      composed_address: null
    },
    TELEPHONE_KINDS: [],
    multiTelephonesInputKey: 0,
    addressKinds: [],
    addressKindsCompany: [],
    addressCenter: null,
    roles: [
      { value: "0", text: 'Administrator' },
      { value: "2", text: 'Employee' },
      { value: "1", text: 'After sale' }
    ],
    paycheckPermission: false,
    certificateSuitability: false,
    contractPermission: false,
    identityDocumentPermission: false,
    postingCertificatePermission: false,
  }),

  computed: {
    ...mapState("constants", ["countries"]),

    selectableKinds() {
      if (this.adminUserType == 'company') {
        return this.addressKindsCompany;
      }
      return this.addressKinds;
    },

    computedMsg() {
      let msg = this.$t("Contact person creation failed");
      for (const el in this.observerErrors) {
        if (this.observerErrors[el].length) {
          msg = this.observerErrors[el][0];
          break;
        }
      }
      return msg;
    },
    getRole() {
      return this.roles.find(el => el.value == this.role).text;
    },
    telephoneNumbers() {
      return this.physicalPersonInfo.telcontact_set.filter(el => el.number != '')
    }
  },

  async mounted() {
    PhysicalPersonAddressService.getKinds()
      .then((res) => res.map(el => ({ value: el.value, text: el.display_name })))
      .then(address => (this.addressKinds = address))
      .catch((err) => {
        console.error(err);
        this.$bvToast.toast(this.$t("Failed to load address kinds. Please try again."), {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
          noAutoHide: true,
        });
      });
    CompanyAddressService.getKinds()
      .then((res) => res.map(el => ({ value: el.value, text: el.display_name })))
      .then(address => (this.addressKindsCompany = address))
      .catch((err) => {
        console.error(err);
        this.$bvToast.toast(this.$t("Failed to load address kinds. Please try again."), {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
          noAutoHide: true,
        });
      });
    PhysicalPersonTelephoneService.getKinds()
      .then(res => res.map(el => ({ value: el.value, text: el.display_name })))
      .then(telKinds => (this.TELEPHONE_KINDS = telKinds))
      .catch(err => {
        console.error(err);
        this.$bvToast.toast(this.$t("Failed to load telephone kinds. Please try again."), {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
          noAutoHide: true,
        });
      })
  },

  methods: {

    handlePhoneUpdate(val) {
      if (val[0]) this.physicalPersonInfo.telcontact_set = [val[0]];
      else this.physicalPersonInfo.telcontact_set = [];
    },
    handleEmailUpdate(val) {
      this.physicalPersonInfo.login_email = val;
    },
    handleRoleUpdate(val) {
      this.role = val;
    },
    updateCertificateSuitability(val) {
      this.certificateSuitability = val;
    },
    updatePaycheckPermission(val) {
      this.paycheckPermission = val
    },
    updateContractPermission(val) {
      this.contractPermission = val
    },
    updateIdentityDocumentPermission(val) {
      this.identityDocumentPermission = val
    },
    updatePostingCertificatePermission(val) {
      this.postingCertificatePermission = val
    },
    async addressRequest() {
      if (this.physicalPersonInfo.place_birth) {
        const convertedAddressesBirth = await AddressService.getOrCreateAddress([{
          address: this.physicalPersonInfo.place_birth,
          manual_address: false
        }]);
        this.physicalPersonInfo.place_birth_id = convertedAddressesBirth[0].id;
      }

      if (this.addresses.address) {
        const convertedAddresses = await AddressService.getOrCreateAddress([this.addresses]);

        const address = { ...this.addresses, address_id: convertedAddresses[0].id }
        delete address.address;
        delete address.composed_address;
        delete address.manual_address;

        this.addresses = [address];
      } else {
        this.addresses = [];
      }
    },

    async submit() {
      await this.addressRequest();
      const body = {
        inviteType: this.physicalPersonInfo.is_employee ? 'employee' : this.inviteType,
        role: this.role,
        email: this.physicalPersonInfo.login_email,
        physicalPerson: {
          id: this.physicalPersonInfo.id,
          name: this.physicalPersonInfo.name,
          surname: this.physicalPersonInfo.surname,
          telcontact_set: this.telephoneNumbers
        },
        userID: this.physicalPersonInfo.id,
        paycheckPermission: this.paycheckPermission,
        certificateSuitability: this.certificateSuitability,
        contractPermission: this.contractPermission,
        identityDocumentPermission: this.identityDocumentPermission,
        postingCertificatePermission: this.postingCertificatePermission,
        title: this.physicalPersonInfo.title
      };
      body.physicalPerson.avatar_tus = this.physicalPersonInfo.avatar_tus ? this.physicalPersonInfo.avatar_tus : null;
      body.physicalPerson.date_birth = this.physicalPersonInfo.date_birth ? this.physicalPersonInfo.date_birth : null;
      body.physicalPerson.place_birth_id = this.physicalPersonInfo.place_birth_id ? this.physicalPersonInfo.place_birth_id : null;
      body.physicalPerson.gender = this.physicalPersonInfo.gender ? this.physicalPersonInfo.gender : null;
      body.physicalPerson.citizenship_set = this.physicalPersonInfo.citizenship_set ? this.physicalPersonInfo.citizenship_set : null;
      body.physicalPerson.physicalpersonaddress_set = this.addresses;
      this.$emit("submit", body);
    },

    async handleNext() {
      const validator = this.$refs[`validator-${this.$refs.userCreateWizardForm.getStep()}`];
      if (validator) {
        await validator.reset();
        const isValid = await validator.validate();
        if (!isValid) {
          if (this.$refs.userCreateWizardForm.getStep() === 2) {
            if (this.addresses.address == null || this.addresses.hasOwnProperty('formatted_address') && this.addresses.formatted_address == null) {
              await Swal.fire({
                title: this.$t("Caution"),
                text: this.$t("Seems like some fields are missing, do you want to continue?(You can modify them and then validate the address with the validate address button)"),
                icon: "warning",
                confirmButtonClass: "btn btn-danger",
                confirmButtonText: "Yes",
                showCancelButton: true,
                cancelButtonText: "No",
                cancelButtonClass: "btn",
              }).then(res => {
                if (!res.isConfirmed) {
                  this.$refs.userCreateWizardForm.goTo(2);
                }
              });
            } else {
              await Swal.fire({
                title: this.$t("Caution"),
                text: this.$t("You must add a location in the address field"),
                icon: "warning",
              }).then(res => {
                if (res.isConfirmed) {
                  this.$refs.userCreateWizardForm.goTo(1);
                }
              });
            }
          } else {
            this.$nextTick();
            return;
          }
        }
      }
      this.$refs.mainValidationObserver.handleSubmit(this.$refs.userCreateWizardForm.goNext());
    },
    changeManualAddress(val) {
      this.addresses.manual_address = val
    },
    updateComposedAddress(val) {
      this.addresses.composed_address = val;
    }
  },
};
</script>

<style scoped>
.wizard-step-container {
  max-width: 600px;
  margin: 0 auto;
}
</style>
