var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('wizard-left', {
    ref: "userCreateWizardForm",
    attrs: {
      "clickable-steps": false
    },
    scopedSlots: _vm._u([{
      key: "wizard-nav-left",
      fn: function fn() {
        return [_c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Physical person setup'),
            "desc": _vm.$t('Add email and details'),
            "icon": "General/User.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Contacts and address'),
            "desc": _vm.$t('Add telephone number and address'),
            "icon": "Map/Marker1.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Completed!'),
            "desc": _vm.$t('Review and Submit'),
            "icon": "General/Like.svg"
          }
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref) {
        var currentStep = _ref.currentStep,
          goPrev = _ref.goPrev;
        return [_c('validation-observer', {
          ref: "mainValidationObserver",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var _vm$addresses, _vm$addresses$address, _vm$addresses2, _vm$addresses2$addres, _vm$addresses$address2, _vm$addresses$address3;
              var handleSubmit = _ref2.handleSubmit;
              return [_c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [_c('keep-alive', [currentStep === 1 ? _c('validation-observer', {
                ref: "validator-1"
              }, [_vm.inviteType == 'user' ? _c('div', [_c('PhysicalPersonInfoForm', _vm._b({
                attrs: {
                  "invite-type": _vm.inviteType,
                  "physical-person-info": _vm.physicalPersonInfo,
                  "role": _vm.role,
                  "certificate-suitability": _vm.certificateSuitability,
                  "paycheck-permission": _vm.paycheckPermission,
                  "contract-permission": _vm.contractPermission,
                  "identity-document-permission": _vm.identityDocumentPermission,
                  "posting-certificate-permission": _vm.postingCertificatePermission
                },
                on: {
                  "updateUserEmail": _vm.handleEmailUpdate,
                  "updateUserRole": _vm.handleRoleUpdate,
                  "updateCertificateSuitability": _vm.updateCertificateSuitability,
                  "updatePaycheckPermission": _vm.updatePaycheckPermission,
                  "updateContractPermission": _vm.updateContractPermission,
                  "updateIdentityDocumentPermission": _vm.updateIdentityDocumentPermission,
                  "updatePostingCertificatePermission": _vm.updatePostingCertificatePermission
                }
              }, 'PhysicalPersonInfoForm', _vm.physicalPersonInfo, false, true))], 1) : _vm._e()]) : _vm._e()], 1)], 1), _c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [currentStep === 2 ? _c('validation-observer', {
                ref: "validator-2"
              }, [_c('div', {
                staticClass: "row"
              }, [_c('div', {
                staticClass: "col-12"
              }, [_c('h4', {
                staticClass: "mb-3 font-weight-bold text-dark"
              }, [_vm._v(_vm._s(_vm.$t("Contacts and address")))])]), _c('div', {
                staticClass: "col-12"
              }, [_c('div', {
                staticClass: "pb-4"
              }, [_c('multi-telephones-input', {
                key: _vm.multiTelephonesInputKey,
                attrs: {
                  "label": "".concat(_vm.$t('Telephone number')),
                  "is-multi": false,
                  "options": _vm.TELEPHONE_KINDS
                },
                model: {
                  value: _vm.physicalPersonInfo.telcontact_set,
                  callback: function callback($$v) {
                    _vm.$set(_vm.physicalPersonInfo, "telcontact_set", $$v);
                  },
                  expression: "physicalPersonInfo.telcontact_set"
                }
              })], 1)]), _c('div', {
                staticClass: "col-12"
              }, [_c('p', {
                staticClass: "mb-6 mt-5 font-weight-bold"
              }, [_vm._v(_vm._s(_vm.$t("Address")) + ":")])]), _c('div', {
                staticClass: "col-9"
              }, [_c('validation-provider', {
                attrs: {
                  "vid": "address_kind"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref3) {
                    var errors = _ref3.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "label": _vm.$t('Kind'),
                        "options": _vm.selectableKinds,
                        "error-messagess": errors
                      },
                      model: {
                        value: _vm.addresses.kind,
                        callback: function callback($$v) {
                          _vm.$set(_vm.addresses, "kind", $$v);
                        },
                        expression: "addresses.kind"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-3"
              }, [_c('div', {
                staticClass: "form-group mb-0 mt-12"
              }, [_c('div', {
                staticClass: "d-flex align-items-center justify-content-end"
              }, [_c('label', {
                staticClass: "col-form-label"
              }, [_vm._v(_vm._s(_vm.$t('Main address')))]), _c('div', {
                staticClass: "ml-3"
              }, [_c('span', {
                staticClass: "switch switch-sm switch-icon"
              }, [_c('label', [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.addresses.main,
                  expression: "addresses.main"
                }],
                attrs: {
                  "type": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(_vm.addresses.main) ? _vm._i(_vm.addresses.main, null) > -1 : _vm.addresses.main
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.addresses.main,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(_vm.addresses, "main", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(_vm.addresses, "main", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(_vm.addresses, "main", $$c);
                    }
                  }
                }
              }), _c('span')])])])])])]), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "vid": "address_address",
                  "rules": {
                    required: true
                  }
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref4) {
                    var errors = _ref4.errors;
                    return [_c('AddressInput', {
                      attrs: {
                        "label": _vm.$t('Address'),
                        "active-step": currentStep === 3,
                        "error-messages": errors,
                        "address-center": _vm.addressCenter,
                        "manual-address": _vm.addresses.manual_address
                      },
                      on: {
                        "change-manual-address": _vm.changeManualAddress,
                        "update-composed-address": _vm.updateComposedAddress
                      },
                      model: {
                        value: _vm.addresses.address,
                        callback: function callback($$v) {
                          _vm.$set(_vm.addresses, "address", $$v);
                        },
                        expression: "addresses.address"
                      }
                    })];
                  }
                }], null, true)
              })], 1)])]) : _vm._e()], 1), currentStep === 3 ? _c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [_c('h3', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Confirm information to send invite")))]), _c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Physical person setup")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("Email")) + ": "), _c('span', [_vm._v(_vm._s(_vm.physicalPersonInfo.login_email))])]), _vm.physicalPersonInfo.title ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("Title")) + ": "), _c('span', [_vm._v(_vm._s(_vm.physicalPersonInfo.title))])]) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(_vm.$t("Name")) + ": "), _c('span', [_vm._v(_vm._s(_vm.physicalPersonInfo.name))])]), _c('div', [_vm._v(" " + _vm._s(_vm.$t("Last name")) + ": "), _c('span', [_vm._v(_vm._s(_vm.physicalPersonInfo.surname))])]), _vm.physicalPersonInfo.gender ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("Gender")) + ": "), _c('span', [_vm._v(_vm._s(_vm.physicalPersonInfo.gender == 'MA' ? _vm.$t("Male") : _vm.$t("Female")))])]) : _vm._e(), _vm.physicalPersonInfo.place_birth ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("Place of birth")) + ": "), _c('span', [_vm._v(_vm._s(_vm.physicalPersonInfo.place_birth.address_components[0].long_name))])]) : _vm._e(), _vm.physicalPersonInfo.date_birth ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("Date of birth")) + ": "), _c('span', [_vm._v(_vm._s(_vm.physicalPersonInfo.date_birth))])]) : _vm._e(), _vm.role ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("Role")) + ": "), _c('span', [_vm._v(_vm._s(_vm.getRole))])]) : _vm._e()]), (_vm$addresses = _vm.addresses) !== null && _vm$addresses !== void 0 && (_vm$addresses$address = _vm$addresses.address) !== null && _vm$addresses$address !== void 0 && _vm$addresses$address.formatted_address || _vm.telephoneNumbers.length > 0 ? _c('div', [_c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Contact and address")))]), _vm.telephoneNumbers.length > 0 ? _c('div', _vm._l(_vm.telephoneNumbers, function (phone) {
                return _c('div', {
                  key: phone.number,
                  staticClass: "text-dark-50 line-height-lg"
                }, [_vm._v(" " + _vm._s(_vm.TELEPHONE_KINDS.find(function (el) {
                  return el.value == phone.kind;
                }).text) + ": " + _vm._s(phone.number) + " ")]);
              }), 0) : _vm._e(), (_vm$addresses2 = _vm.addresses) !== null && _vm$addresses2 !== void 0 && (_vm$addresses2$addres = _vm$addresses2.address) !== null && _vm$addresses2$addres !== void 0 && _vm$addresses2$addres.formatted_address ? _c('div', [_c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_vm._v(" " + _vm._s(_vm.$t("Address")) + ": "), _vm.addresses.manual_address ? _c('span', [_vm._v(_vm._s(_vm.addresses.composed_address.formatted))]) : _c('span', [_vm._v(_vm._s((_vm$addresses$address2 = (_vm$addresses$address3 = _vm.addresses.address) === null || _vm$addresses$address3 === void 0 ? void 0 : _vm$addresses$address3.formatted_address) !== null && _vm$addresses$address2 !== void 0 ? _vm$addresses$address2 : "No address found"))]), _vm.addresses.description ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("Description")) + ": "), _c('span', [_vm._v(_vm._s(_vm.addresses.description))])]) : _vm._e()])]) : _vm._e()]) : _vm._e()]) : _vm._e(), _c('wizard-actions-left', [_c('div', {
                staticClass: "mr-2"
              }, [_c('wizard-prev-btn-left', {
                on: {
                  "click": goPrev
                }
              })], 1), _c('div', [_c('wizard-submit-btn-left', {
                attrs: {
                  "label": _vm.$t('Save as Confirmed')
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }), _c('wizard-next-btn-left', {
                on: {
                  "click": _vm.handleNext
                }
              })], 1)])];
            }
          }], null, true)
        })];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }